<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>权限管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div style="padding-bottom:10px">
                <el-button type="primary" icon="el-icon-circle-plus-outline" @click="openAuth">开通权限</el-button>
            </div>
            <div class="list" v-loading="loading">
                <el-table
                :data="tableData"
                :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                style="width: 100%"
                v-loading="loading"
                row-key="id"
                border
                :expand-row-keys="defaultArr"
                :tree-props="{children: 'child', hasChildren: 'hasChildren'}"
                >
                    <el-table-column
                    prop=""
                    align="center"
                    label=""
                    width="50">
                    </el-table-column>
                    <el-table-column
                    prop="name"
                    align="left"
                    label="权限名称"
                    width="200">
                    </el-table-column>
                    <el-table-column
                    prop="page"
                    label="权限路径"
                    width="300">
                    </el-table-column>
                    <el-table-column
                    prop="icon"
                    label="权限图标"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="code"
                    label="权限code">
                    </el-table-column>
                    <el-table-column
                    prop="zindex"
                    label="菜单排序">
                    </el-table-column>
                    <el-table-column
                    prop="address"
                    width="220"
                    label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="handleCreate(scope.row.id,scope.row.pid)">编辑</el-button>
                            <!-- <el-tooltip class="item" effect="dark" content="添加子节点" placement="top-start"> -->
                                <el-button v-if="scope.row.pid==0" type="primary"  @click="openAuth1(scope.row.id,scope.row.pid)">添加</el-button>
                            <!-- </el-tooltip> -->
                            
                            <el-button type="danger"  @click="deleteLimit(scope.row.id,scope.row.pid)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- 添加 -->
        <el-dialog title="提示" :visible.sync="dialogVisible1" :close-on-click-modal="false" :destroy-on-close="false" @close="canvel">
        <el-form
            :label-position="labelPosition"
            label-width="80px"
            :model="formLabelAdd1"
            :rules="rules"
            ref="formLabelAdd1"
        >
            <el-form-item label="权限名称" prop="name">
            <el-input v-model="formLabelAdd1.name"></el-input>
            </el-form-item>
            <el-form-item label="权限路径" prop="page">
            <el-input v-model="formLabelAdd1.page"></el-input>
            </el-form-item>
            <el-form-item v-if="pid==null" label="权限图标" prop="url">
            <el-input v-model="formLabelAdd1.url"></el-input>
            </el-form-item>
            <el-form-item label="code" prop="code">
            <el-input v-model="formLabelAdd1.code"></el-input>
            </el-form-item>
            <el-form-item label="菜单排序" prop="zindex">
            <el-input type="number" v-model="formLabelAdd1.zindex" placeholder="请输入数字"></el-input>
            </el-form-item>
            <el-form-item label="描述">
            <el-input v-model="formLabelAdd1.descpt"></el-input>
            </el-form-item>
            <el-form-item class="flex justify-content-end">
            <el-button @click="canvel">取 消</el-button>
            <el-button type="primary" v-preventReClick @click="handAdd('formLabelAdd1')">添加</el-button>
            </el-form-item>
        </el-form>
        </el-dialog>
        <!-- 修改 -->
        <el-dialog title="提示" :visible.sync="dialogVisible" :close-on-click-modal="false"	@close="dialogVisible = false;pid = null">
        <el-form
            :label-position="labelPosition"
            label-width="80px"
            :model="formLabelAdd"
            :rules="rules"
            ref="formLabelAdd"
        >
            <el-form-item label="权限名称" prop="name">
            <el-input v-model="formLabelAdd.name"></el-input>
            </el-form-item>
            <el-form-item label="权限路径" prop="page">
            <el-input v-model="formLabelAdd.page"></el-input>
            </el-form-item>
            <el-form-item v-if="pid==0" label="权限图标" prop="url">
            <el-input v-model="formLabelAdd.url"></el-input>
            </el-form-item>
            <el-form-item label="code" prop="code">
            <el-input v-model="formLabelAdd.code"></el-input>
            </el-form-item>
            <el-form-item label="菜单排序" prop="zindex">
            <el-input type="number" v-model="formLabelAdd.zindex" placeholder="请输入数字"></el-input>
            </el-form-item>
            <el-form-item label="描述">
            <el-input v-model="formLabelAdd.descpt"></el-input>
            </el-form-item>
            <el-form-item class="flex justify-content-end">
            <el-button @click="dialogVisible = false;pid = null">取 消</el-button>
            <el-button type="primary" v-preventReClick @click="handEdit('formLabelAdd')" :loading="btnLoad">修改</el-button>
            </el-form-item>
        </el-form>
        </el-dialog>
    </div>
</template>
<script>
import _api from '@/api/index'
import apiUrl from '@/api/public'
export default {
    data(){
        return{
            tableData: [], //权限列表数据
            defaultArr:[],//默认展开行
            dialogVisible:false,
            dialogVisible1:false,
            formLabelAdd1: {
                name:"",
                page:"",
                descpt:"",
                code:"",
                zindex:null,
                url:""
            },
            formLabelAdd: {
                name:"",
                page:"",
                descpt:"",
                code:"",
                zindex:null,
                url:""
            },
            rules: {
                name: [
                { required: true, message: "权限名称不能为空", trigger: "blur" }
                ],
                istype: [
                { required: true, message: "请选择权限分类", trigger: "blur" }
                ],
                page: [
                { required: true, message: "权限路径不能为空", trigger: "blur" }
                ],
                code: [
                { required: true, message: "权限code不能为空", trigger: "blur" }
                ],
                zindex: [
                { required: true, message: "菜单排序不能为空", trigger: "blur" }
                ],
                url: [
                { required: true, message: "权限图标不能为空", trigger: "blur" }
                ]
            },
            list:[],
            data:[],
            loading:false,
            
            labelPosition: "right", //控制表单对齐方式
            btnLoad:false,
            id:null,
            pid:null
        }
    },
    created(){
        this.getPermission()
    },
    methods:{
        //开通权限
        openAuth(){
            this.dialogVisible1=true
            this.id1=1
        },
        openAuth1(id,pid){
            this.id = id
            this.pid = pid
            this.dialogVisible1=true
            this.id1=0
        },
        handleCreate(id,pid) {
            console.log(pid)
            this.id = id;
            this.dialogVisible = true;
            this.pid = pid
            this.selectByPerId(id)
        },
        // 取消
        canvel(){
            this.dialogVisible1 = false
            this.btnLoad=false
            this.formLabelAdd1.zindex=this.formLabelAdd1.page=this.formLabelAdd1.descpt=this.formLabelAdd1.code=this.formLabelAdd1.name=''
            this.pid = null
        },
        //添加
        handAdd(formName) {
            this.btnLoad=true
            if(this.id1==1){
                this.id=0
            }
            const newChild = {
                pid:this.id,
                descpt:this.formLabelAdd1.descpt,
                code:this.formLabelAdd1.code,
                name:this.formLabelAdd1.name,
                page:this.formLabelAdd1.page,
                zindex:parseInt(this.formLabelAdd1.zindex),
                icon:this.formLabelAdd1.url,
            };
            this.$refs[formName].validate(valid => {
                if (valid) {
                    _api.post(apiUrl.addPermission,newChild
                    ).then(res=>{
                        if (res.success) {
                            this.$message.success('添加权限成功')
                            this.canvel()
                            this.getPermission()
                        }
                    }).catch(err =>{
                        console.log(err)
                    })
                } else {
                this.$message.error("信息未填写完整，请检查！");
                return false;
                }
            });
        },
        handEdit(formName) {
            this.btnLoad=true
            const newChild = {
                id:this.id,
                pid:this.pid,
                descpt:this.formLabelAdd.descpt,
                code:this.formLabelAdd.code,
                name:this.formLabelAdd.name,
                page:this.formLabelAdd.page,
                zindex:parseInt(this.formLabelAdd.zindex),
                icon:this.formLabelAdd.url,
            };
            this.$refs[formName].validate(valid => {
                if (valid) {
                    _api.post(apiUrl.addPermission,newChild
                    ).then(res=>{
                        if (res.success) {
                            this.$message.success('修改权限成功')
                            this.btnLoad=false
                            this.dialogVisible = false
                            this.pid = null
                            this.getPermission()
                        }
                    }).catch(err =>{
                        console.log(err)
                    })
                } else {
                this.$message.error("信息未填写完整，请检查！");
                return false;
                }
            });
        },
        getPermission(){
            this.loading=true
            _api.get(apiUrl.queryPermission,{
                }
            ).then(res=>{
                this.loading=false
                if(res.success){
                    this.tableData=res.data
                }
            }).catch(err =>{
                console.log(err)
            })
        },
        selectByPerId(id){
            _api.get(apiUrl.selectByPerId,{
                perId:id
            }
            ).then(res=>{
                if (res.success) {
                    this.formLabelAdd.name = res.data.name
                    this.formLabelAdd.descpt = res.data.descpt
                    this.formLabelAdd.url = res.data.icon
                    this.formLabelAdd.page = res.data.page
                    this.formLabelAdd.zindex = res.data.zindex
                    this.formLabelAdd.code = res.data.code
                    this.pid = res.data.pid
                }
            }).catch(err =>{
                console.log(err)
            })
        },
        deleteLimit(id,pid){
            let messageText = ""
            if(pid==0){
                messageText = "若删除父权限，该权限下的所有子权限也将被删除，是否继续?"
            }else{
                messageText = "此操作将永久删除, 是否继续?"
            }
            this.$confirm(messageText, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                _api.get(apiUrl.deletePermission,{
                        permissionId:id
                    }
                    ).then(res=>{
                        if (res.success) {
                            this.$message.success('删除权限成功')
                            this.getPermission()
                        }
                    }).catch(err =>{
                        console.log(err)
                    })
                })
                .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消删除"
                });
            });
        },
        getimgurl(data){
            this.formLabelAdd1.url=data
        },
        getimgurl1(data){
            this.formLabelAdd.url=data
        },
    }
}
</script>
<style>
 .el-loading-spinner .path{    stroke: #fe8196;}
 .el-switch__label{color: #bdbdbd;}
 .el-switch__label.el-switch__label--right.is-active{color: #2ad1b8;}
 .el-switch__label.el-switch__label--left.is-active{color: #fe8496;}
</style>
<style scoped>
@import "../../assets/css/booth.css";
.serarch{ overflow: hidden;}

</style>
